import CrudEmployeeWorkLog from './worklogs/CrudEmployeeWorkLog';
import { getEmployeeFormDefinition } from './form-elements';

export function getFormDefinition(vue) {
  const employeeElements = getEmployeeFormDefinition(vue);
  return {
    type: 'tab',
    saveButtons: [true, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, false], // Tab 1, tab 2, ....
    labels: ['common.tabGeneral', 'employee.worklogs'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      employeeElements,
      {
        worklogs: {
          type: CrudEmployeeWorkLog,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            parentIdFilterField: 'employeeID',
            permissionCode: 'EmployeeWorklog'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return { groups: [layoutFullWidth] };
}

export function getEditLayoutDefinition(vue) {
  return { groups: [layoutFullWidth, layoutFullWidth] };
}
