import { validatorRequire, validatorRequireNumber, validatorTime } from '@/utils/validators';
import TimePicker from '@/components/DSE/TimePicker';
import MDinput from '@/components/MDinput';
import DateTimePicker from '@/components/DSE/DateTimePicker';

export function getFormDefinition(vue) {
  return {
    workedAt: {
      type: DateTimePicker,
      props: {
        caption: 'employeeWorklog.workedAt',
        type: 'date',
        format: vue.$store.getters.dateFormat
      },
      rules: [{ validator: validatorRequire }]
    },
    startedAt: {
      type: TimePicker,
      props: {
        caption: 'employeeWorklog.startedAt'
      },
      rules: [{ validator: validatorRequire }]
    },
    endsAt: {
      type: TimePicker,
      props: {
        caption: 'employeeWorklog.endsAt'
      },
      rules: [{ validator: validatorRequire }]
    },
    breakDuration: {
      defaultValue: '00:00',
      type: MDinput,
      props: {
        caption: 'employeeWorklog.breakDuration'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorTime
        }
      ]
    },
    costs: {
      type: MDinput,
      defaultValue: null,
      props: {
        type: 'currency',
        caption: 'employee.cost',
        min: 0,
        max: 100
      },
      rules: [{ validator: validatorRequireNumber }],
      valueOnFormChange: (form, oldvalue) => {
        return form.costs || vue.currentCosts;
      }
    },
    hourlyWage: {
      type: MDinput,
      defaultValue: null,
      props: {
        type: 'currency',
        caption: 'employee.hourly_wage',
        min: 0,
        max: 100
      },
      rules: [{ validator: validatorRequireNumber }],
      valueOnFormChange: (form, oldvalue) => {
        return form.hourlyWage || vue.currentHourlyWage;
      }
    },
    remarks: {
      type: MDinput,
      defaultValue: null,
      props: {
        maxlength: 255
      }
    }
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
