<template>
  <embedded-crud-table
    :entity="entity"
    :entity-store="entityStore"
    :columns="columns"
    :generate-summary="generateSummary"
    :definition="definition"
    :caption="caption"
    :disabled="disabled"
    :parent-id-filter-field="parentIdFilterField"
    :open-in-fullscreen="openInFullscreen"
    :required="required"
    :create-item-from-form-data="createItemFromFormData"
    :create-form-data-from-item="createFormDataFromItem"
    :permission-code="permissionCode"
    :parent-i-d="parentID"
  />
</template>

<script>
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import { toDateStringVisual } from '@/utils/date';
import { formatPrice } from '@/utils';
import formMixin from './form-mixin';

const formatDate = function(row, column, cellValue) {
  return toDateStringVisual(cellValue);
};
const formatTimeSpan = function(row, column, cellValue) {
  const hours = `${cellValue.hours}`.padStart(2, '0');
  const minutes = `${cellValue.minutes}`.padStart(2, '0');
  return `${hours}:${minutes}`;
};
const formatCosts = function(row, column, cellValue) {
  return formatPrice(cellValue);
};

export default {
  components: {
    EmbeddedCrudTable
  },
  mixins: [formMixin],
  props: {
    caption: String,
    parentIdFilterField: {
      type: String,
      required: true
    },
    permissionCode: String,
    disabled: {
      default: false,
      type: Boolean
    },
    columns: {
      default: () => [
        { field: 'workedAt', label: 'employeeWorklog.workedAt', formatter: formatDate, filter: { type: 'date' } },
        { field: 'startedAt', label: 'employeeWorklog.startedAt' },
        { field: 'endsAt', label: 'employeeWorklog.endsAt' },
        { field: 'breakDuration', label: 'employeeWorklog.breakDuration' },
        { field: 'duration', label: 'employeeWorklog.hours', formatter: formatTimeSpan },
        { field: 'costs', label: 'employee.cost', formatter: formatCosts },
        { field: 'hourlyWage', label: 'employee.hourly_wage', formatter: formatCosts },
        { field: 'remarks', label: 'common.remarks' }
      ],
      type: Array
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    parentID: Number
  },
  methods: {
    generateSummary(row) {
      return `${toDateStringVisual(row.workedAt)} `;
    }
  }
};
</script>

<style lang="scss" scoped></style>
