import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    },
    currentHourlyWage() {
      const employee = this.$store.getters['employee/getLoadedItemByID'](+this.$route.params.id);
      return employee && employee.hourlyWage;
    },
    currentCosts() {
      const employee = this.$store.getters['employee/getLoadedItemByID'](+this.$route.params.id);
      return employee && employee.cost;
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'EmployeeWorklog',
    returnTo: 'EmployeeWorklogs'
  }),
  methods: {
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
